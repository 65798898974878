/* -------------------------------------   Login Page CSS :: START     ----------------------------------*/

/* font */

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "IniSans";
  src: url(font/IniSans-VGvnZ.otf);
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(font/Poppins-Regular.ttf);
}

body {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* font-family: 'Poppins-Regular'; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  color: #000;
  font-weight: 700;
  /* font-family: 'Poppins-SemiBold'; */
}

p {
  font-size: 14px;
  line-height: 1.1428em;
  /* font-family: 'Poppins-Regular'; */
  color: #000;
  font-weight: 400;
  /* line-height: 25px; */
  margin-bottom: 0px;
}

/* .row,
.col {
  vertical-align: middle;
  align-items: center;
} */

/* slider section css:: start */

/* .main-background-row
{
padding: 25px 0 ;
} */

.login-page-body {
  background-image: url(./image/shape.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}
.carousel-item .text-heading {
  font-size: 20px;
  letter-spacing: 1.2px;
  color: #fff;
  line-height: 1.7;
  font-weight: 700;
}

.carousel-item .card {
  position: static;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: transparent;
  max-height: 300px;
}

.row-1 {
  height: 210px;
  width: 100%;
}

.carousel-item .text-paragraph {
  font-size: 15px;
  color: #fff;
  letter-spacing: 1.5px;
  line-height: 1.7;
  font-weight: 100;
}

.buttom-ul-li li {
  list-style: none;
  border: 2px solid #fff;
  box-shadow: inset 0 0 0 0 #c42532;
  transition: 0.5s ease-in;
  color: #fff;
  margin: 0 10px;
}

.bottom-btn-list ul {
  display: flex;
}

.bottom-btn-list ul li {
  width: 30%;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  /* border: 2px solid transparent; */
  transition: all 0.4s ease-in-out 0s;
}

.bottom-btn-list-small-device ul li {
  width: 50%;
}

.bottom-btn-list-small-device .sm-large-ul li {
  width: 100%;
}

.bottom-btn-list ul li:hover {
  box-shadow: inset 0 0 0 2em #fff;
  color: #000;
  cursor: pointer;
}

.carousel-item .carousel-inner {
  position: relative;
  left: 14%;
}

.carousel-inner img {
  width: 70%;
}

.carousel-indicators {
  display: inline-grid;
  top: 65%;
  transform: translate(-46%, -20%);
  bottom: unset;
  left: -22%;
}

.carousel-indicators li {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.button {
  margin-top: 30px;
  padding-bottom: 20px;
}

.carousel-item .learn-more {
  color: white;
  border: 2px solid white;
  box-shadow: inset 0 0 0 0 white;
  transition: 0.5s ease-in;
  text-decoration: none;
  border-radius: 2px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  transition: 0.5s ease-in-out;
  margin-top: 15px;
  width: 100%;
}

.learn-more:hover {
  box-shadow: inset 0 0 0 2em white;
  color: #000;
}

.button .a1 {
  margin-left: -25px;
}

.button .a2 {
  margin-left: -16px;
}

/* slider section css:: end */

/* form section css:: start */
.forgot_password {
  font-size: 13px;
  cursor: pointer;
  color: #000 !important;
  text-decoration: none !important;
}
.login-form {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login-card .card-body {
  padding: 90px 81px 43px;
}

.login-card-title {
  font-size: 29px;
  margin-bottom: 9px;
  margin-top: 10px;
  padding-bottom: 20px;
}

.login-card-description {
  font-size: 15px;
  color: #a0a0a0;
  margin-bottom: 25px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-options-wrapper {
  display: table;
  margin-bottom: 7px;
  font-size: 14px;
  color: #919aa3;
}

.sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.login-card .form-control {
  border: 1px solid #d5dae2;
  padding: 8px 13px;
  margin-bottom: 10px;
  border-radius: 4px;
  min-height: 45px;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-weight: normal;
}

.login-card-footer-text {
  font-size: 18px;
  color: #a0a0a0;
  text-align: center;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  background-color: transparent;
}

.login-card-footer-text {
  font-size: 18px;
  color: #a0a0a0;
  text-align: center;
  margin-bottom: 0;
}

.login-card .login-btn {
  padding: 8px 13px;
  margin-bottom: 5px;
  border-radius: 4px;
  min-height: 42px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #ff0a0a;
  border: 2px solid #a5202a;
  color: black;
  transition: 0.25s;
  box-shadow: inset 0 0 0 0 #ff0a0a;
  box-shadow: inset 0 0 0 0 #a5202a;
  transition: 0.5s ease-in;
}

.login-btn:hover {
  box-shadow: inset 0 0 0 2em #ff0a0a;
  box-shadow: inset 0 0 0 2em #a5202a;
  color: #fff;
}

/* form section css::end */

/* -------------------------------------   Login Page CSS :: END     ----------------------------------*/

@media (min-width: 320px) and (max-width: 732px) {
  .login-page-body {
    background-image: unset;
  }
  .main-background-col {
    background-image: url(image/sm-md.png);
  }

  .main-background-row {
    padding: 0;
  }
  .login-card-title {
    font-size: 29px;
    text-align: center;
    font-weight: normal;
    color: #fff;
    margin-bottom: 9px;
  }
  .carousel-indicators {
    display: inline-grid;
    bottom: 41%;
    left: -96%;
  }
  .carousel-item .text-heading {
    font-size: 20px;
    color: #000;
    height: 89px;
  }
  .carousel-item .text-paragraph {
    font-size: 12px;
    margin-top: 0px;
    color: #000;
  }
  .carousel-inner img {
    position: relative;
    width: 72%;
    margin: 0 auto;
  }
  .row-1 {
    height: 273px;
  }

  .login-card .card-body {
    padding: 43px 29px 43px;
  }
  .small-device {
    margin-top: 23px;
  }
  .bottom-btn-list ul {
    margin-top: 0px;
  }
  .carousel-indicators li {
    background-color: black;
  }
  .carousel-indicators {
    display: inline-grid;
    bottom: 13%;
    left: -96%;
    display: none;
  }

  .carousel-item .learn-more {
    color: black;
    margin-left: 10px;
    border: 2px solid black;
    box-shadow: inset 0 0 0 0 #ab222d;
    transition: 0.5s ease-in;
  }
  .carousel-item .learn-more:hover {
    box-shadow: inset 0 0 0 2em #ab222d;
    color: #fff;
    border: none;
  }
  .login-card-title {
    color: #000;
  }
  .bottom-btn-list ul li {
    background-color: #ab222d;
    border: 2px solid transparent;
  }

  .bottom-btn-list ul li:hover {
    border: 2px solid #ab222d;
    color: white;
  }
  .carousel {
    padding: 12px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .carousel {
    padding: 22px 24px;
  }
  .carousel-inner img {
    width: 75%;
    margin: 0 auto;
  }
  .carousel-item .learn-more {
    font-size: 12px;
  }
  .row-1 {
    height: 185px;
  }
  .carousel-item .text-heading {
    height: 60px;
    font-size: 15px;
  }
  .carousel-item .text-slider {
    height: 30px;
  }
  .carousel-item .text-paragraph {
    font-size: 12px;
  }
  .bottom-btn-list ul li {
    padding: 0px;
    font-size: 14px;
  }
  .buttom-ul-li li {
    margin: -7px 6px;
  }
  .bottom-btn-list ul {
    margin-top: 0px;
    padding: 3px;
  }
  .login-card .card-body {
    padding: 23px 11px 43px;
    margin-left: 30px;
  }
}

@media (min-width: 1366px) and (max-width: 1024) {
  .carousel-inner img {
    width: 80%;
    margin: 0 auto;
  }
  .bottom-btn-list ul {
    display: flex;
    margin-top: 10px;
  }
  .buttom-ul-li li {
    margin: -16px 2px;
  }
  .row-1 {
    height: 240px;
    width: 100%;
  }
  .login-card .card-body {
    padding: 90px 48px 43px;
  }
  .carousel-item .learn-more {
    color: white;
    border: 2px solid white;
    box-shadow: inset 0 0 0 0 white;
    transition: 0.5s ease-in;
    text-decoration: none;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    transition: 0.5s ease-in-out;
    margin-top: 20px;
    width: 100%;
  }
  .learn-more:hover {
    box-shadow: inset 0 0 0 2em white;
    color: #000;
  }
}

@media (min-width: 720px) and (max-width: 991.5px) {
  body {
    background-image: unset !important;
  }
  .main-background-row .main-background-col {
    background-image: url(image/sm-md.png) !important;
  }
  .carousel {
    padding: 21px 97px;
  }
  .row-1 {
    height: 190px;
  }
  .main-background-row {
    padding: 0;
  }
  .carousel-item .text-heading {
    font-size: 20px;
    color: #000;
  }
  .carousel-item .text-paragraph {
    font-size: 12px;
    margin-top: 0px;
    color: #000;
  }
  .bottom-btn-list ul li {
    background-color: #ab222d;
    border: 2px solid transparent;
  }

  .bottom-btn-list ul li:hover {
    border: 2px solid #ab222d;
    color: white;
  }
  .login-card img {
    background-color: #fff;
    padding: 10px;
    width: 50%;
    height: auto;
    text-align: center;
  }
  .carousel-item .learn-more {
    color: black;
    margin-left: 10px;
    border: 2px solid black;
    box-shadow: inset 0 0 0 0 #ab222d;
    transition: 0.5s ease-in;
  }
  .carousel-item .learn-more:hover {
    box-shadow: inset 0 0 0 2em #ab222d;
    color: #fff;
    border: none;
  }
  .login-card .card-body {
    padding: 21px 126px 56px;
  }
}
@media (max-width: 1800px) and (min-width: 1440px) {
  .carousel-item .text-heading {
    font-size: 22px;
    /* height: 80px; */
    /* margin-top: 48px; */
  }
  .carousel-inner img {
    width: 65%;
    margin: 0 auto;
  }
  .carousel-item .text-paragraph {
    font-size: 17px;
  }
  .carousel-item button {
    font-size: 18px;
  }
  .row-1 {
    height: 260px;
    width: 100%;
  }
}
@media (max-width: 2500px) and (min-width: 1920px) {
  /* body 
{
background-image: url(../images/shape-11.png);
} */
  .carousel-item .text-heading {
    font-size: 29px;
    height: 95px;
  }
  .carousel-item .text-paragraph {
    font-size: 24px;
    margin-top: 10px;
  }
  .carousel-item .text-slider {
    height: 50px;
  }
  .carousel-item .learn-more {
    font-size: 25px;
    font-size: calc(12px + 0.35vw) !important;
    margin-top: 10px;
  }
  .row-1 {
    height: 335px;
    width: 100%;
  }
  .bottom-btn-list ul li {
    font-size: 25px;
    font-size: calc(12px + 0.35vw) !important;
  }
  .carousel-indicators {
    top: 60%;
    transform: translate(-50%, -20%);
  }
  .login-card-title {
    font-size: 33px;
  }
  .login-card-description {
    font-size: 24px;
    margin-top: 35px;
  }
  .login-card input.form-control {
    font-size: 14p.5x;
    padding: 0px 9px;
    line-height: 1.5;
  }
  .login-card .card-body {
    padding: 91px 85px 62px;
  }
  .text-reset {
    color: inherit !important;
    font-size: 24px;
  }
  .login-card .card-body img {
    vertical-align: middle;
    height: 160px;
  }
  .carousel-indicators li {
    width: 20px;
    height: 20px;
  }
  .carousel-inner img {
    width: 58%;
    margin: 0 auto;
  }
}
@media only screen and (max-device-width: 1280px) and (min-device-height: 800) {
  .carousel-inner img {
    left: -11px;
    width: 80%;
    margin: 0 auto;
  }
  .carousel-item .text-heading {
    font-size: 24px;
    height: 77px;
  }
}

/* for landscape mode::Start */
/* @media  (max-width:1025px) and (orientation: landscape)
{
.carousel-inner img {
width:73%;
margin: 0 auto;
}
.carousel {
padding: -6px 24px;
}
} */
@media (max-width: 823px) and (orientation: landscape) {
  .carousel {
    padding: 25px -1px;
  }
}
@media (max-width: 720px) and (orientation: landscape) {
  .carousel {
    padding: 6px 45px;
    margin-top: -24px;
  }
}

@media (min-width: 992px) and (max-width: 1420px) {
  .login-page-body {
    background-size: auto;
  }
}

.password-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.password-input-top {
  position: relative;
}
.password-icon i {
  color: var(--thm-color-red);
  cursor: pointer;
}
